import React from "react";

import "../../css/Planets/Planets.css";

const PlanetsHeader = () => {
  return (
    <div className="heading d-flex justify-content-between mb-2">
      <h3>Domains</h3>
    </div>
  );
};

export default PlanetsHeader;
